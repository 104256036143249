import { render, staticRenderFns } from "./TheNavigation.vue?vue&type=template&id=72534950&scoped=true&"
import script from "./TheNavigation.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TheNavigation.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TheNavigation.vue?vue&type=style&index=0&id=72534950&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72534950",
  null
  
)

export default component.exports